<template>
  <div>
    <AppHeader />
    <TTView>
      <VRow>
        <VCol class="text-center">
          <h1 class="font-weight-medium">
            {{ $t('surveyIsFinished') }}
          </h1>
        </VCol>
      </VRow>

      <VRow justify="center">
        <VCol
          lg="6"
          md="6"
          sm="12"
          xs="12"
        >
          <VImg
            :src="require('@/assets/img/surv_fin.svg')"
            width="100%"
          />
        </VCol>
      </VRow>
    </TTView>
  </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';
import TTView from '@/components/TTView.vue';

export default {
  name: 'SurveyFinished',
  components: { AppHeader, TTView },
};
</script>
